import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import LandingPage from './Components/LandingPage';
import Services from './Components/Services';
import About from './Components/About';
import Footer from './Components/Footer';

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header />
                <Routes>
                    {/* Strona główna */}
                    <Route path="/" element={
                        <>
                            <LandingPage />
                            <Services />
                            <About />
                            <Footer />
                        </>
                    } />
                    {/* Strona kontakt (wyświetla to samo co strona główna) */}
                    <Route path="/kontakt" element={
                        <>
                            <LandingPage />
                            <Services />
                            <About />
                            <Footer />
                        </>
                    } />
                    {/* Dowolna inna ścieżka niezdefiniowana powyżej - wyświetla stronę główną */}
                    <Route path="*" element={
                        <>
                            <LandingPage />
                            <Services />
                            <About />
                            <Footer />
                        </>
                    } />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
