import React from 'react';

function Services(props) {
    return (
        <div className={'services-container'} id={'services'}>
            <div className={'services'}>
                <div className={'services-card'}>
                    <div className={'services-card-up'}>
                    </div>
                    <div className={'services-card-down'}>
                        <div className={'services-card-icon'}>
                        </div>
                        <div className={'services-card-down-title'}>
                            Usługi Kanalizacyjne
                        </div>
                        <div className={'services-card-down-description'}>
                            Oferujemy kompleksowe usługi kanalizacyjne, które są dostosowane do indywidualnych potrzeb
                            naszych klientów. Nasza firma ma wieloletnie doświadczenie w branży, co pozwala nam zapewnić
                            profesjonalne podejście i wysoką jakość wykonywanych prac.
                        </div>
                    </div>
                </div>
                <div className={'services-card'}>
                    <div className={'services-card-up1'}>
                    </div>
                    <div className={'services-card-down'}>
                        <div className={'services-card-icon'}>
                        </div>
                        <div className={'services-card-down-title'}>
                            Usługi Gazowe
                        </div>
                        <div className={'services-card-down-description'}>
                            Oferjuemy kompleksowy montaż oraz podłączenie kuchenek gazowych,
                            zapewniając jednocześnie usługę podbijania gwarancji na nowo zakupiony sprzęt, przy
                            zachowaniu najwyższych standardów bezpieczeństwa i profesjonalizmu.
                        </div>
                    </div>
                </div>
                <div className={'services-card'}>
                    <div className={'services-card-up2'}>
                    </div>
                    <div className={'services-card-down'}>
                        <div className={'services-card-icon'}>
                        </div>
                        <div className={'services-card-down-title'}>
                            Usługi Hydrauliczne
                        </div>
                        <div className={'services-card-down-description'}>
                            Oferujemy kompleksowe usługi hydrauliczne. Gwarantujemy terminowość i solidność wykonywanych
                            prac, zapewniając bezpieczne i niezawodne działanie Twoich instalacji. Skontaktuj się z
                            nami, aby uzyskać profesjonalne wsparcie.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;