import React, {useState} from 'react';
import logo from '../Assets/plumbing_512.png';
import burgericon from '../Assets/burger-icon.svg';
import {Link} from "react-scroll";
import fixly from '../Assets/fixly.png';

const Header = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
        console.log(isMenuOpen);
    };

    return (
        <>
            <div className="header-container">
                <div className="header">
                    <div className="header-left-container">
                        <div className="header-left">
                            <a href="/">
                                <img src={logo} alt="Logo Hydraulik Mariusz Krasuski"/>
                                <p>Hydraulik Mariusz Krasuski</p>
                            </a>
                        </div>
                    </div>
                    <div className="header-right-container">
                        <div className={"header-right"}>
                            <Link to="home" smooth={true} duration={1200}>Strona Główna</Link>
                            <Link to="services" smooth={true} duration={1200}>Usługi</Link>
                            <Link to="about" smooth={true} duration={1200}>O Nas</Link>
                            <Link to="home" smooth={true} duration={1200}>
                                <button>Kontakt 795-928-063</button>
                            </Link>
                        </div>
                        <div className="header-burger" onClick={handleMenuToggle}>
                            <img src={burgericon} alt="burger"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`header-right ${isMenuOpen ? 'open' : 'close'}`}>
                <Link to="home" smooth={true} duration={1200}>Strona Główna</Link>
                <Link to="services" smooth={true} duration={1200}>Usługi</Link>
                <Link to="about" smooth={true} duration={1200}>O Nas</Link>
                <a href={'https://fixly.pl/profil/hARVKhDZ'}>
                    <img src={fixly} alt={'fixly'} id={'mobile-fixly'}/>
                </a>
                <Link to="home" smooth={true} duration={1200}>
                    <button>Kontakt 795-928-063</button>
                </Link>
            </div>
        </>
    );
};

export default Header;
