import React from 'react';
import professional from "../Assets/worker.png"
import expierience from "../Assets/quality.png"
import equipped from "../Assets/maintenance.png"

function About(props) {
    return (
        <div className={'about-container'} id={'about'}>
            <div className={'about'}>
                <p>O Nas</p>
                <h1>Witaj w naszej firmie hydraulicznej</h1>
                <h3>Specjalizujemy się w szerokim zakresie usług. Działamy w
                    obszarze usług kanalizacyjnych, montażu kuchenek gazowych oraz kompleksowych prac hydraulicznych.
                    Nasze doświadczenie oraz profesjonalne podejście pozwalają nam dostosować się do Twoich unikalnych
                    potrzeb i zagwarantować wysoką jakość świadczonych usług.</h3>
                <div className={'about-section'}>
                    <div className={'about-section-card'}>
                        <div className={'about-section-card-icon'}>
                            <img src={professional} alt="professional-plumber"/>
                            PROFESJONALIZM
                        </div>
                        <div className={'about-section-space'}>
                            <hr/>
                        </div>
                        <div className={'about-section-card-title'}>
                            Jesteśmy profesjonalistami w swojej dziedzinie i zawsze dajemy z siebie 100%.
                        </div>
                    </div>
                    <div className={'about-section-card'}>
                        <div className={'about-section-card-icon'}>
                            <img src={expierience} alt="professional-plumber"/>
                            DOŚWIADCZENIE
                        </div>
                        <div className={'about-section-space'}>
                            <hr/>
                        </div>
                        <div className={'about-section-card-title'}>
                            Jesteśmy ekspertami w dziedzinie hydrauliki i zawsze wkładamy maksimum wysiłku w każde
                            zadanie, które podejmujemy.
                        </div>
                    </div>
                    <div className={'about-section-card'}>
                        <div className={'about-section-card-icon'}>
                            <img src={equipped} alt="professional-plumber"/>
                            WYPOSAŻENIE
                        </div>
                        <div className={'about-section-space'}>
                            <hr/>
                        </div>
                        <div className={'about-section-card-title'}>
                            Nasza firma dysponuje zaawansowanym i różnorodnym sprzętem, który umożliwia nam skuteczne
                            działania w każdym zadaniu.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
