import React from 'react';
import logo from '../Assets/technician.png';
import fixly from '../Assets/fixly.png';

function Footer(props) {
    return (
        <div className={'footer-container'}>
            <div className={'footer'}>
                <div className={'footer-left'}>
                    <div className={'footer-left-title'}>
                        <img src={logo} alt={'logo'}/>
                        <h1>Hydraulik Mariusz Krasuski</h1>
                    </div>
                    <p>Nasza firma zapewnia kompleksowe usługi hydrauliczne i kanalizacyjne, dostępne gdziekolwiek
                        jesteś. Gwarantujemy profesjonalizm i ekspertyzę na każdym kroku współpracy, bez względu na
                        odległość.</p>
                </div>
                <div className={'footer-right'}>
                    ZNAJDŹ NAS NA FIXLY
                    <a href={'https://fixly.pl/profil/hARVKhDZ'}>
                        <img src={fixly} alt={'fixly'}/>
                    </a>
                    <h2>TEL. 795-928-063</h2>
                </div>
            </div>
        </div>
    );
}

export default Footer;
