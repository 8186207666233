import React from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

function LandingPage(props) {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Imię jest wymagane'),
        email: Yup.string().email('Nieprawidłowy adres email').required('Email jest wymagany'),
        phoneNumber: Yup.string().required('Numer telefonu jest wymagany'),
        message: Yup.string().required('Wiadomość jest wymagana'),
    });

    const initialValues = {
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
    };

    const onSubmit = (values, {resetForm}) => {
        emailjs.sendForm('service_8i5sx6q', 'template_fwugkgg', '#contact-form', 'EIPWs7huDoywAomMZ')
            .then((response) => {
                resetForm();
            }, (error) => {
                console.log('Error sending message:', error);
            });
    };


    return (
        <div className={'landing-container'} id={'home'}>
            <div className={'landing'}>
                <div className={'landing-left'}>
                    <div className={'landing-left-title-mobile'}>
                        795-928-063
                    </div>
                    <div className={'landing-left-title'}>
                        Zapewniamy profesjonalne usługi hydrauliczne i kanalizacyjne
                    </div>
                    <div className={'landing-left-text'}>
                        Nasza firma oferuje mobilne usługi hydrauliczne i kanalizacyjne, dostępne w całym regionie.
                        Możesz liczyć na naszą ekspertyzę i profesjonalizm, niezależnie od Twojej lokalizacji.
                    </div>
                </div>
                <div className={'landing-right'}>
                    <div className={'card'}>
                        <div className={'card-title'}>
                            Skontaktuj się z nami
                            <p>795-928-063</p>
                        </div>
                        <div className={'card-text'}>
                            Wyślij nam wiadomość, a my odpowiemy tak szybko, jak to możliwe.
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            <Form id="contact-form">
                                <div className={'contact-form'}>
                                    <Field type="text" id="name" name="name" placeholder={'Imię'}/>
                                    <ErrorMessage name="name" component="div" className="error-message"/>
                                    <Field type="text" id="phoneNumber" name="phoneNumber" placeholder={'Telefon'}/>
                                    <ErrorMessage name="phoneNumber" component="div" className="error-message"/>
                                    <Field type="email" id="email" name="email" placeholder={'E-mail'}/>
                                    <ErrorMessage name="email" component="div" className="error-message"/>
                                    <Field as="textarea" id="message" name="message" placeholder={'Wiadomość'}/>
                                    <ErrorMessage name="message" component="div" className="error-message"/>
                                    <button type="submit" className={'send-button'}>{'Wyślij'}</button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
